@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.Home_container__bCOhY {
  padding: 0 2rem;
}

.Home_main__nLjiQ {
  min-height: 100vh;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home_title__T09hD a {
  color: #0070f3;
  text-decoration: none;
}

.Home_title__T09hD a:hover,
.Home_title__T09hD a:focus,
.Home_title__T09hD a:active {
  text-decoration: underline;
}

.Home_title__T09hD {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  text-align: center;
}

.Home_main-content__ukr6P {
  padding-top: 0;
}

.Home_home_wrapper__hyspn {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4a8cb0",endColorstr="#00355b",GradientType=1);
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  overflow: hidden;
}

.Home_hero_bg__NUYL7  {
  height: 75vh;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 540px;
}

.Home_main_video__fnsO_ {
  opacity: .2;
  position: absolute;
  max-width: none;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.Home_home_wrapper__hyspn div {
  z-index: 1;
}

.Home_prompt__kowJ9 {
  font-size: 200%;
  padding-top: 0.75rem;
}
.UnauthenticatedView_login_container__7lE9C {
	padding: 0;
	margin: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background: #dde2da;
}
.UnauthenticatedView_login_box__IHm94 {
	border: 1px solid #d1d1d152;
	background: #f3f7fb;
	margin-right: auto;
	max-width: 440px;
	padding: 44px;
	margin-bottom: 28px;
	background-color: #fff;
	box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
	min-width: 320px;
	min-height: 338px;
	overflow: hidden;
	position: absolute;
	left: 50%;
	width: 440px;
	margin-left: -220px;
	top: 50%;
	margin-top: -169px;
}
.UnauthenticatedView_login_box__IHm94 p {
	padding: 45px 0 110px;
	font-weight: 300;
}
.UnauthenticatedView_login_box__IHm94 button {
	font-weight: 600;
}
